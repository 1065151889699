<template>
    <v-container>
        <h1>{{ $t('resetPassword.enterNewPassword') }}</h1>
        <v-form class="py-6">
            <v-text-field variant="underlined" persistent-placeholder :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" :label="$t('resetPassword.newPassword')" v-model="newPassword" @blur="isFocused[0] = false" @focus="isFocused[0] = true"></v-text-field>
        </v-form>
        <v-btn block class="my-4" color="primary" @click="passwordReset">
            {{ $t('resetPassword.resetPasswordButton') }}
        </v-btn>
    </v-container>
</template>

<script>


export default {
    name: 'ResetPassword',
    data() {
        return {
            newPassword: '',
            isFocused: [false],
            showPassword: false,
        };
    },
    methods: {
        async passwordReset() {
            try {
                await this.$ApiService.passwordReset({
                    token: this.$route.query.token,
                    password: this.newPassword
                });
                this.$router.back();
            } catch (error) {
                //console.error(error);
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
