<template>
    <v-container>
        <h1>{{ $t('accountSettings.title') }}</h1>

        <v-card class="text-left mx-auto my-3">
            <v-card-text class="text-left my-1 py-1 text-disabled">
                {{ $t('accountSettings.accountName') }}
            </v-card-text>
            <v-card-text class="text-h6 py-1 mx-0 text-center">
                <v-text-field :variant="variantName" v-model="account.accountname" :append-icon="editAccountNameIcon" :readonly="editAccountName" @click:append="switchAccountNameEdit" />
            </v-card-text>
        </v-card>
        
        <v-card class="text-left mx-auto my-3">
            <v-card-text class="text-left my-1 py-1 text-disabled">
                {{ $t('accountSettings.payQR') }}
            </v-card-text>
            <v-card-text class="text-h6 py-1 text-center" style="background-color: #ffffff;">
                <qrcode-vue :value="qrValue" :size="100" :level="'L'" color="'#FFFFFFF'" background-color="'#000000'"></qrcode-vue>
            </v-card-text>
        </v-card>

        <v-card class="text-left mx-auto my-3">
            <v-card-text class="text-left my-1 py-1 text-disabled">
                {{ $t('accountSettings.accountPIN') }}
            </v-card-text>
            <v-card-text class="text-center text-h4 my-1 py-3 ">
                <v-text-field class="text-center" :variant="variantPin" v-model="account.pin" :append-icon="editAccountPinIcon" :readonly="editAccountPin" @click:append="switchAccountPinEdit" />
            </v-card-text>
        </v-card>

        <v-card class="text-left mx-auto my-3">
            <v-card-text class="text-left my-1 py-0 text-disabled">
                {{ $t('accountSettings.notifications') }}
            </v-card-text>
            <v-card-text class="py-0 px-0">
                <v-table style="background-color:transparent" class="py-0 custom-table">
                    <tbody>
                        <tr>
                            <td class="text-left">{{ $t('accountSettings.onPurchase') }}</td>
                            <td class="text-right"><v-switch class="custom-switch" v-model="notficationPurchase" hide-details inset color="primary"></v-switch></td>
                        </tr>
                        <tr>
                            <td class="text-left">{{ $t('accountSettings.onLowBalance') }}</td>
                            <td class="text-right"><v-switch class="custom-switch" v-model="notficationLowBalance" hide-details inset color="primary"></v-switch></td>
                        </tr>
                        <tr v-if="notficationLowBalance">
                            <td class="text-left">{{ $t('accountSettings.balanceAmount') }}</td>
                            <td class="text-right"><v-text-field class="text-right right-aligned-textfield" prefix="€&nbsp;" variant="underlined"></v-text-field></td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>
        <v-btn block class="my-4" color="error" @click="topUp">{{ $t('accountSettings.deleteAccount') }}</v-btn>
    
    </v-container>
</template>

<script>

import QrcodeVue from 'qrcode.vue';
 
export default {
    name: 'AccountSettings',
    props: {
        id: String,
    },
    components: {
        QrcodeVue,
    },
    data() {
        return {
            accountid: this.$route.params.id,
            notficationPurchase: true,
            notficationLowBalance: false,
            editAccountName: true,
            editAccountPin: true,
            isAccountNameEditable: false,
            isAccountPinEditable: false,
            editAccountNameIcon: "mdi-pencil",
            editAccountPinIcon: "mdi-pencil",
            variantName: "plain",
            variantPin: "plain",
            qrValue: 'https://www.example.com',
            account: {
                name: "",
                pin: "",
            }
        };
    },
    mounted() {
        this.$store.commit('global/SET_LOADER', true);
        this.fetchAccountDetails();
        this.$store.commit('global/SET_LOADER', false);
    },
    methods: {
        switchAccountNameEdit() {
            this.isAccountNameEditable = !this.isAccountNameEditable;
            this.editAccountName = !this.isAccountNameEditable;
            this.variantName = this.isAccountNameEditable ? "underlined" : "plain";
            this.editAccountNameIcon = this.isAccountNameEditable ? "mdi-checkbox-marked-circle" : "mdi-pencil";
        },
        switchAccountPinEdit() {
            this.isAccountPinEditable = !this.isAccountPinEditable;
            this.editAccountPin = !this.isAccountPinEditable;
            this.variantPin = this.isAccountPinEditable ? "underlined" : "plain";
            this.editAccountPinIcon = this.isAccountPinEditable ? "mdi-checkbox-marked-circle" : "mdi-pencil";
        },
        async fetchAccountDetails() {
            try {
                const response = await this.$ApiService.fetchAccountDetails(this.accountid);
                this.account = response.data;
            } catch (error) {
                this.$errorHandler(this.$options.name,error)
                
            }
        }
    },
};
</script>

<style scoped>
.custom-switch {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
}

 

.custom-table tr, .custom-table td {
    border-top: none !important;
    border-bottom: none !important;
}
</style>
