<template>
    <v-container>
        <h1>{{ $t('editAccount.title') }}</h1>
        <v-form>
            <v-text-field :label="$t('editAccount.accountName')" v-model="account.name"></v-text-field>
            <v-text-field :label="$t('editAccount.beneficiaryName')" v-model="account.beneficiary"></v-text-field>
            <v-btn color="primary" @click="saveChanges">{{ $t('editAccount.saveChanges') }}</v-btn>
        </v-form>
    </v-container>
</template>

<script>
export default {
    name: 'EditAccount',
    props: {
        id: String,
    },
    data() {
        return {
            account: { name: "", beneficiary: "" }, // Placeholder for account to edit
        };
    },
    mounted() {
        this.fetchAccount(this.id);
    },
    methods: {
        async fetchAccount() {
            try {
                const response = await this.$ApiService.fetchAccountDetails(this.id);
                this.account = response.data;
            } catch (error) {
                console.error("Failed to fetch account details:", error);
            }
        },
        async saveChanges() {
            try {
                await this.$ApiService.editAccount(this.id, this.account);
                this.$router.push('/manage-accounts');
            } catch (error) {
                //console.error("Failed to save changes:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
