<template>
    <v-container>
        <h1>{{ $t('beneficiaries.title') }}</h1>
        <v-card class="text-left mx-auto my-3" v-if="beneficiaries.length > 0">
            <v-table style="background-color:transparent" class="py-0">
                <tbody>
                    <tr v-for="beneficiary in beneficiaries" :key="beneficiary.beneficiaryid">
                        <td class="text-left">
                            {{ beneficiary.name }}
                        </td>
                        <td class="text-right" @click="deleteBeneficiary(beneficiary.beneficiaryid)">{{ $t('beneficiaries.remove') }}</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
        <v-btn block class="my-4" color="primary" @click="addBeneficiary">{{ $t('beneficiaries.addBeneficiary') }}</v-btn>
    </v-container>
</template>

<script>




export default {
    name: 'beneficiaryDetails',
    data() {
        return {
            beneficiaries: [],
        };
    },
    mounted() {
        this.fetchBeneficiaries();
    },
    methods: {
        async fetchBeneficiaries() {
            try {
                const response = await this.$ApiService.fetchBeneficiaries();
                this.beneficiaries = response.data;
            } catch (error) {
                //console.error("Failed to fetch beneficiaries:", error);
                this.$errorHandler(this.$options.name, error)
            }
        },
        addBeneficiary() {
            this.$router.push('/beneficiary-add');
        },
        async deleteBeneficiary(beneficiaryId) {
            try {
                await this.$ApiService.deleteBeneficiary(beneficiaryId);
                this.fetchBeneficiaries();
                this.$toast.success(this.$t('beneficiaries.removeSuccess'));
            } catch (error) {
                //console.error("Failed to delete beneficiary:", error);
                //this.$toast.error(this.$t('beneficiaries.removeFail'));
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
