import axios from 'axios';

// Set up a base Axios instance
const apiClient = axios.create({
  //  baseURL: 'http://localhost:3000/api',
  baseURL: process.env.VUE_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
  },
});


// Interceptor to include token in every request
apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  // Append a unique query parameter to the request URL to prevent caching
  const url = new URL(config.url, config.baseURL);
  url.searchParams.set('_', new Date().getTime());
  config.url = url.pathname + url.search;
  //this.$store.commit('global/SET_LOADER', true);
  return config;
}, error => {
  return Promise.reject(error);
});

const handleErrors = error => {
  axios.interceptors.response.use(
    response => response,
    error => {
      let errorDesc = { status: error.response ? error.response.status : 0, desc: error.response ? error.response.data : 'Network Error' };
      if (errorDesc.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      console.error('API error:', errorDesc);
      return Promise.reject(errorDesc); // Reject with errorDesc to ensure consistency in error handling
    }
  );
  let errorDesc = { status: error.response ? error.response.status : 0, desc: error.response ? error.response.data.message : 'Network Error' };
  console.error('API error:', errorDesc);
  throw errorDesc;
};


export default {
  fetchMerchants() {
    return apiClient.get('/merchants').catch(handleErrors);
  },
  // Account-related calls
  fetchAccounts() {
    return apiClient.get('/accounts').catch(handleErrors);
  },
  fetchAccountsBalance() {
    return apiClient.get('/accounts/balance').catch(handleErrors);
  },
  fetchAccountDetails(accountId) {
    return apiClient.get(`/accounts/${accountId}`).catch(handleErrors);
  },
  fetchSingleAccountBalance(accountId) {
    return apiClient.get(`/accounts/balance/${accountId}`).catch(handleErrors);
  },
  updateAccountLimits(accountId, transactionData) {
    return apiClient.put(`/accounts/limit/${accountId}`, transactionData).catch(handleErrors);
  },

  addAccount(accountData) {
    return apiClient.post('/accounts', accountData).catch(handleErrors);
  },
  editAccount(accountId, accountData) {
    return apiClient.put(`/accounts/${accountId}`, accountData).catch(handleErrors);
  },
  deleteAccount(accountId) {
    return apiClient.delete(`/accounts/${accountId}`).catch(handleErrors);
  },

  // Transaction-related calls
  fetchTransactions() {
    return apiClient.get('/transactions').catch(handleErrors);
  },

  fetchAccountTransaction(accountId, limit) {
    return apiClient.get(`/transactions/all/${accountId}`, limit).catch(handleErrors);
  },

  fetchMerchantProducts(accountId) {
    return apiClient.get(`/products/merchant/${accountId}`).catch(handleErrors);
  },

  // Fetches all products marked as non-selected for a specific account
  fetchAccountProducts(accountId) {
    return apiClient.get(`/accounts/non-selected-products/${accountId}`).catch(handleErrors);
  },

  // Updates the list of non-selected products for a specific account
  updateAccountProducts(accountId, nonSelectedProductIds) {
    const accountData = { nonSelectedProductIds };
    return apiClient.put(`/accounts/update-non-selected-products/${accountId}`, accountData).catch(handleErrors);
  },


  accountTopUp(accountId, transactionData) {
    return apiClient.post(`/transactions/${accountId}`, transactionData).catch(handleErrors);
  },

  // Authentication
  login(credentials) {
    return apiClient.post('/auth/login', credentials).then(response => {
      localStorage.setItem('token', response.data.token);
      return response.data;
    }).catch(handleErrors);
  },
  register(credentials) {
    return apiClient.post('/auth/register', credentials).then(response => {
      localStorage.setItem('token', response.data.token);
      return response.data;
    }).catch(handleErrors);
  },

  sendResetEmail(email){
    return apiClient.post(`/auth/resetemail`, email).catch(handleErrors);
  },
  logout() {
    localStorage.removeItem('token');
    // Additional logout logic as needed
  },
  // Profile and settings
  updateProfile(profileData) {
    return apiClient.put('/profile', profileData).catch(handleErrors);
  },
  updateNotificationSettings(settings) {
    return apiClient.put('/notifications', settings).catch(handleErrors);
  },

  // Help, support, and analytics
  fetchHelpTopics() {
    return apiClient.get('/help').catch(handleErrors);
  },
  fetchReports(params) {
    return apiClient.get('/reports', { params }).catch(handleErrors);
  },
  transferBetweenAccounts(params) {
    return apiClient.post('/transactions/transfer', params).catch(handleErrors);
  },
  //--------------PAYMENTS
  getTokenAndCardDetails(params) {
    return apiClient.post('/transactions/transfer', params).catch(handleErrors);
  },
  addPaymentMethod(params) {
    return apiClient.post('/payments', params).catch(handleErrors);
  },
  fetchPayments() {
    return apiClient.get('/payments').catch(handleErrors);
  },
  deletePaymentMethod(paymentId) {
    return apiClient.delete(`/payments/${paymentId}`).catch(handleErrors);
  },

  addBeneficiary(params) {
    return apiClient.post('/beneficiaries', params).catch(handleErrors);
  },
  fetchBeneficiaries() {
    return apiClient.get('/beneficiaries').catch(handleErrors);
  },
  deleteBeneficiary(beneficiaryId) {
    return apiClient.delete(`/beneficiaries/${beneficiaryId}`).catch(handleErrors);
  },

  passwordReset(params) {
    return apiClient.post('/auth/resetpassword', params).catch(handleErrors);
  }
};


/*
// General error handling
const handleErrors = error => {
  // Log or handle errors as needed
  //console.error(error.response.status);

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('authToken');
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  if (error.response.status == '401') {
    console.error('API error:', error);
    localStorage.removeItem('token');
    window.location.href = '/login';
  }

  if (error.response.status != '402') {
    var errorDesc = { status: error.response.status, desc: error.response.data }
    console.error('API error:', errorDesc);
  }
  //throw error;
  throw errorDesc;
}; */