<template>
    <v-container>
        <h1>{{ $t('notificationsSetup.title') }}</h1>
        <v-form>
            <v-switch :label="$t('notificationsSetup.emailAlerts')" v-model="emailAlerts"></v-switch>
            <v-switch :label="$t('notificationsSetup.smsAlerts')" v-model="smsAlerts"></v-switch>
            <v-btn color="primary" @click="saveSettings">{{ $t('notificationsSetup.saveSettings') }}</v-btn>
        </v-form>
    </v-container>
</template>

<script>
export default {
    name: 'NotificationsSetup',
    data() {
        return {
            emailAlerts: false,
            smsAlerts: false,
        };
    },
    methods: {
        async saveSettings() {
            try {
                await this.$ApiService.updateNotificationSettings({
                    emailAlerts: this.emailAlerts,
                    smsAlerts: this.smsAlerts,
                });
                // Assuming notification for success
                this.$toast.success(this.$t('notificationsSetup.successMessage'));
            } catch (error) {
                console.error("Failed to update notification settings:", error);
                // Assuming notification for error
                //this.$toast.error(this.$t('notificationsSetup.errorMessage'));
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
