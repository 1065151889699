const state = {
  viewname: '',
};

const mutations = {
  changeview(state, { viewname }) {
    state.viewname = viewname.viewname;
  },
};

const actions = {
  changepage({ commit }, viewname) {
    commit('changeview', { viewname }); // Commit success mutation
  }
};

const getters = {
  viewname: state => state.viewname,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};