const state = {
    loader: true, // Initial loader state
  };
  
  const mutations = {
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
  };
  
  const actions = {
    updateLoader({ commit }, payload) {
      commit('SET_LOADER', payload);
    },
  };
  
  export default {
    namespaced: true, // Use namespaced to access this module as global/loader, global/SET_LOADER, etc.
    state,
    mutations,
    actions,
  };
  