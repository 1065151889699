import Vuex from 'vuex'
import auth from './modules/auth';
import page from './modules/page';
import global from './modules/global'; // Import the global module


export default new Vuex.Store({
  state: {},
  viewname: "",
  mutations: {},
  actions: {},
  modules: {
    auth,
    page,
    global
  }
})
