<template>
    <v-container>
        <h1>{{ $t('home.title') }}</h1>
        <v-card class="text-left mx-auto my-3" v-if="accounts.length > 0">
            <v-table style="background-color:transparent" class="py-1 my-1">
                <template v-slot:default>
                    <tbody>
                        <tr v-for="account in accounts" :key="account.accountid" @click="$router.push(`/account-details/${account.accountid}`)" class="account-row">
                            
                            <td class="text-left mx-7 my-7" style="width:30px;">
                            <div class="vertical-align">
                                <v-avatar size="36">
                                    <img :src="account.avatarUrl || '/avatar.jpg'" alt="Avatar">
                                </v-avatar>
                            </div>
                            </td>
                            <td class="text-left">
                                 
                                <h3>{{ account.accountname }}</h3> 
                                {{ $formatCurrency(account.balance) }}
                         
                            </td>
                            <td class="text-right">
                                <div class="vertical-align" style="justify-content: right;">
                                <svg-icon type="mdi" :path="pathChevronRight"></svg-icon>
                            
                            </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-table>
        </v-card>

        <v-btn block class="my-4" variant="text" @click="$router.push('/add-account')">{{ $t('home.addAccountButton') }}</v-btn>
    </v-container>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import {  mdiChevronRight} from '@mdi/js';

export default {
    name: 'Home',
    components: {
        SvgIcon
    },
    data() {
        return {
            accounts: [],
            pathChevronRight: mdiChevronRight,
        };
    },
    mounted() {
        this.$store.commit('global/SET_LOADER', true);
        this.fetchAccounts();
        this.$store.commit('global/SET_LOADER', false);
    },
    methods: {
        async fetchAccounts() {
            try {
                const response = await  this.$ApiService.fetchAccountsBalance();
                this.accounts = response.data;
            } catch (error) {
                 
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
<style scoped>
.vertical-align {
    display: flex;
}
</style>
