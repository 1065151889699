<template>
    <v-container>
        <h1>{{ $t('accountTopUp.title') }}</h1>
        <v-form class="py-5">
            <v-table style="background-color:transparent" class="py-7">
                <tr>
                    <td v-for="i in [5, 10, 20, 32]" :key="i" class="mx-2" style="width: 25%;">
                        <v-btn style="width: 80%;" color="primary" variant="outlined" @click="setAmount(i)">{{ $formatCurrency(i) }}</v-btn>
                    </td>
                </tr>
            </v-table>
            <v-select class="py-1" :label="$t('accountTopUp.selectAccount')" :items="accounts" item-title="accountname" variant="underlined" item-value="accountid" v-model="selectedAccount" />
            <v-select class="py-1" :error-messages="isPaymentValid" :label="$t('accountTopUp.paymentMethod')" :items="payments" item-title="paymentname" variant="underlined" item-value="paymentid" v-model="selectedPayment" />
            <v-text-field :error-messages="isAmountValid" persistent-placeholder inputmode="decimal" pattern="[0-9]*" clearable :prefix="$t('accountTopUp.currencyPrefix')" :label="$t('accountTopUp.amount')" type="text" variant="underlined" v-model="amount" @input="validateAndFormatAmount('amount')"></v-text-field>
            <v-btn block class="my-4" color="primary" @click="topUp">{{ $t('accountTopUp.topUpButton') }}</v-btn>
        </v-form>
    </v-container>
</template>

<script>

//
//

export default {
    name: 'AccountTopUp',
    data() {
        return {
            accounts: [],
            payments: [],
            validate: false,
            selectedAccount: '',
            selectedPayment: '',
            amount: "",
            amountRules: [
                v => !!v || this.$t('accountTopUp.amountRequired'),
                v => parseFloat(v) > 0 || this.$t('accountTopUp.amountGreaterThanZero'),
            ],
        };
    },
    mounted() {
        this.$store.commit('global/SET_LOADER', true);
        this.fetchAccounts();
        this.fetchPayments();
        this.selectedAccount = this.$route.params.id;
        this.$store.commit('global/SET_LOADER', false);
    },
    watch: {
        selectedPayment() {
            if (this.selectedPayment == '-1') { this.$router.push('/payment-add') }
            console.log(this.selectedPayment)
        },
    },
    computed: {
        isPaymentValid() {
            if (!this.validate) { return "" }
            if (this.selectedPayment && this.selectedPayment != "-1") { return "" }
            return this.$t('accountTopUp.selectPaymentMethodError')
        },
        isAmountValid() {
            if (!this.validate) { return "" }
            if (this.amount > 0) { return "" }
            return this.$t('accountTopUp.amountOverZeroError')
        },
        isFormValid() {
            if (this.selectedPayment && this.selectedPayment != "-1" && this.amount > 0) { return true }
            return false
        }
    },
    methods: {
        setAmount(amount) {
            this.amount = amount;
        },

        async fetchAccounts() {
            try {
                const response = await this.$ApiService.fetchAccounts();
                this.accounts = response.data;
            } catch (error) {
                this.$errorHandler(this.$options.name, error)
                //this.$toast.error(this.$t("accountTopUp.fetchAccountsError") + error);
            }
        },
        async fetchPayments() {
            try {
                const response = await this.$ApiService.fetchPayments();

                this.payments = response.data.map(payment => ({
                    paymentid: payment.paymentid,
                    "paymentname": `${payment.cardtype} (***${payment.cardnumber})`
                }));
                if (this.payments.length > 0) { this.selectedPayment = this.payments[0].paymentid }
                this.payments.push({ paymentname: this.$t('accountTopUp.addPaymentMethod'), paymentid: "-1" });
            } catch (error) {
                this.$errorHandler(this.$options.name, error)
            }
        },
        validateAndFormatAmount(field) {
            let value = parseFloat(this[field]);
            if (!isNaN(value)) {
                value = Math.min(1000, value);
                this[field] = value
            } else {
                this[field] = null;
            }
        },
        async topUp() {
            this.validate = true;
            if (!this.isFormValid) { return; }
            try {
                const transaction = { amount: this.amount, type: "TopUp" }
                await this.$ApiService.accountTopUp(this.selectedAccount, transaction);
                this.$toast.success(this.$t('success.topup'), { onClose: () => { this.$router.back(); } })
            } catch (error) {
                //this.$toast.error(this.$t("fail.topup"));
                this.$errorHandler(this.$options.name, error)
            }
        },
    },
};
</script>
