<template>
    <v-container>
        <h1>{{ $t('accountTransfer.title') }}</h1>
        <v-form>
            <v-table style="background-color:transparent" class="py-5">
                <tr>
                    <td v-for="i in [5, 10, 20, 32]" :key="i" class="mx-2" style="width: 25%;">
                        <v-btn style="width: 80%;" color="primary" variant="outlined" @click="setAmount(i)">{{ $formatCurrency(i) }}</v-btn>
                    </td>
                </tr>
            </v-table>
            <v-select variant="underlined" :error-messages="fromAccountErrors" :label="$t('accountTransfer.from')" :items="accounts" item-title="accountname" item-value="accountid" return-object v-model="fromAccount"></v-select>
            <v-select variant="underlined" :error-messages="toAccountErrors" :label="$t('accountTransfer.to')" :items="filteredAccounts" item-title="accountname" item-value="accountid" v-model="toAccount"></v-select>
            <v-text-field :error-messages="amountErrors" @input="validateAndFormatAmount('amount')" persistent-placeholder inputmode="decimal" pattern="[0-9]*" clearable prefix="€&nbsp;" :label="$t('accountTransfer.amount')" :attrs="{ inputmode: 'decimal', pattern: '[0-9]*' }" type="text" variant="underlined" v-model="amount"></v-text-field>
            <v-btn block class="my-4" color="primary" @click="transfer">{{ $t('accountTransfer.transferButton') }}</v-btn>
        </v-form>
    </v-container>
</template>
<script>

export default {
    name: 'AccountTransfer',
    data() {
        return {
            accounts: [],
            fromAccount: null,
            toAccount: null,
            amount: "",
            filteredAccounts: [],
            toCheck: false
        };
    },
    watch: {
        fromAccount() {
            this.updateToOptions();
            this.toAccount = null;
        },
    },
    mounted() {
        this.$store.commit('global/SET_LOADER', true);
        this.fetchAccounts();
        this.$store.commit('global/SET_LOADER', false);
    },
    computed: {
        fromAccountErrors() {
            const errors = [];
            if (!this.toCheck) { return errors }
            if (!this.fromAccount || !this.fromAccount.accountid) { errors.push(this.$t('accountTransfer.errors.selectFromAccount')) }
            return errors;
        },
        toAccountErrors() {
            const errors = [];
            if (!this.toCheck) { return errors }
            if (!this.toAccount) { errors.push(this.$t('accountTransfer.errors.selectToAccount')) }
            return errors;
        },
        amountErrors() {
            const errors = [];
            if (!this.toCheck) { return errors }
            if (!this.amount > 0) { errors.push(this.$t('accountTransfer.errors.amountError')) }
            if (this.fromAccount && this.fromAccount.balance < this.amount) { errors.push(this.$t('accountTransfer.errors.availiableBalance')) }
            return errors;
        },
        isFormValid() {
            if (!this.toCheck) { return true }
            if (!this.fromAccount || this.fromAccount.balance < this.amount) { return false }
            return this.amount > 0 && this.fromAccount.accountid && this.toAccount;
        },
    },
    methods: {
        setAmount(amount) {
            this.amount = amount;
        },
        async fetchAccounts() {
            try {
                const response = await this.$ApiService.fetchAccountsBalance();
                this.accounts = response.data;
            } catch (error) {
                this.$errorHandler(this.$options.name, error)
            }
        },
        validateAndFormatAmount(field) {
            let value = parseFloat(this[field]);
            if (!isNaN(value)) {
                value = Math.min(1000, value);
                this[field] = value
            } else {
                this[field] = null;
            }
        },
        updateToOptions() {
            this.filteredAccounts = this.accounts.filter(option => option.accountid !== this.fromAccount.accountid);
        },
        async transfer() {
            console.log(this.fromAccount)
            this.toCheck = true;
            if (!this.isFormValid) { return; }
            try {
                this.$store.commit('global/SET_LOADER', true);
                var params = { fromAccount: this.fromAccount.accountid, toAccount: this.toAccount, amount: this.amount }
                await this.$ApiService.transferBetweenAccounts(params);
                this.$store.commit('global/SET_LOADER', false);
                this.$toast.success(this.$t('accountTransfer.successMessage'), { onClose: () => { this.$router.back(); } })
            } catch (error) {
                //console.error("Failed to transfer:", error);
                this.$errorHandler(this.$options.name, error)
            }
        },
    },
};
</script>
