<template>
    <v-container>
        <h1>{{ $t('transactionsHistory.title') }}</h1>
        <v-data-table :items="groupedTransactions" :headers="headers" density="compact" class="text-left elevation-1">
            <template v-slot:item="{ item }">
                <!-- Group header -->
                <tr v-if="item.isGroup">
                    <td colspan="100%">
                        <strong>{{ item.date }}</strong>
                    </td>
                </tr>
                <!-- Transaction row -->
                <tr v-else>
                    <td colspan="1">
                        <!-- Display only the time part for transaction -->
                        <span class="text-primary-darken-1">{{ extractTime($fixDate(item.created_at)) }}</span> | {{ $typeDescription(item.type) }}
                    </td>
                    <td colspan="1" class="text-right">
                        <!-- Currency format and interaction -->
                        {{ $formatCurrency(item.amount) }}
                        <img style="width: 10px; height:auto" src="/right-arrow.png" @click="$router.push(`/transaction-details/${item.id}`)" />
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>




<script>

import 'vuetify/dist/vuetify.min.css';



export default {
    name: 'ViewTransactions',
    data() {
        return {
            transactions: [], // Placeholder for transactions data
            headers: [],
            accountid: ''
        };
    },
    mounted() {
        this.accountid = this.$route.params.id;
        this.headers = [
            {
                title: 'Transaction',
                align: 'start', // Change alignment here
                value: 'transaction'
            },
            { title: 'Amount', value: 'amount', align: 'end' }
        ]
        this.fetchAccountTransaction();

    },
    computed: {
        groupedTransactions() {
            const groups = this.transactions.reduce((acc, transaction) => {
                // Extract date part only for grouping
                const datePart = this.$fixDate(transaction.created_at).split(' ')[0]; // Get "DD/MM/YY"
                if (!acc[datePart]) {acc[datePart] = [];}
                acc[datePart].push(transaction);
                return acc;
            }, {});

            // Convert groups object back into an array format suitable for v-data-table
            const result = [];
            Object.keys(groups).forEach(date => {
                // Push a group header or separator
                result.push({ isGroup: true, date: date });
                // Push transactions belonging to this group
                result.push(...groups[date]);
            });
            return result;
        }
    },


    methods: {
        async fetchAccountTransaction() {
            try {
                this.$store.commit('global/SET_LOADER', true);
                const response = await this.$ApiService.fetchAccountTransaction(this.accountid, { limit: 100 });
                this.transactions = response.data;
                this.$store.commit('global/SET_LOADER', false);
            } catch (error) {
                this.$errorHandler(this.$options.name,error)
                
            }
        },
        extractTime(datetimeStr) {
            const timePart = datetimeStr.split(' ')[1];
            return timePart;
        }
    },
};
</script>
