const state = {
  token: localStorage.getItem('token') || '',
  user: null,
};

const mutations = {
  auth_success(state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  logout(state) {
    state.token = '';
    state.user = null;
  },
};

const actions = {
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      setTimeout(() => { // Simulate async operation
        // Example condition to simulate login failure
        if (credentials.email === "error@example.com") {
          reject("Invalid login credentials"); // Use reject in case of failure
        } else {
          const token = 'simulated-token';
          const user = { id: '1', name: 'John Doe', email: credentials.email, role: 'owner' }; // Include role in user object
  
          localStorage.setItem('token', token);
          localStorage.setItem('userrole', user.role); // Store user role
          commit('auth_success', { token, user }); // Commit success mutation
          resolve(); // Resolve the promise after successful login
        }
      }, 1000);
    });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      localStorage.removeItem('token'); // Remove the token from localStorage
      commit('logout'); // Commit the logout mutation
      resolve();
    });
  },
};

const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  user: state => state.user,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};