<template>
    <v-container>
        <h1>{{ $t('resetPassword.title') }}</h1>
        <v-form>
            <v-text-field :label="$t('resetPassword.email')" v-model="email" variant="underlined"></v-text-field>
            <v-btn block class="my-4 action" color="primary" @click="sendResetEmail">{{ $t('resetPassword.sendButton') }}</v-btn>
            <v-btn block text color="primary" @click="backToLogin">{{ $t('resetPassword.backToLogin') }}</v-btn>
        </v-form>
    </v-container>
</template>

<script>
export default {
    name: 'ForgotPassword',
    data() {
        return {
            email: '',
        };
    },
    methods: {
        async sendResetEmail() {
            try {
                await this.$ApiService.sendResetEmail({
                    email: this.email,
                });
            //    this.$notifier('A reset link has been sent to your email.');
            } catch (error) {
            //    this.$errorHandler(this.$options.name, error)
            }
        },
        backToLogin() {
            this.$router.push('/login');
        }
    },
};
</script>
