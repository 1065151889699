<template>
    <v-container>
        <h1>{{ $t('paymentMethods.title') }}</h1>
        <v-card class="text-left mx-auto my-3" v-if="payments.length > 0">
            <v-table style="background-color:transparent" class="py-0">
                <tbody>
                    <tr v-for="payment in payments" :key="payment.paymentid">
                        <td class="text-left">
                            {{ payment.paymentname }}
                        </td>
                        <td class="text-right" @click="deletePaymentMethod(payment.paymentid)">{{ $t('paymentMethods.remove') }}</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
        <v-btn block class="my-4" color="primary" @click="addPaymentMethod">{{ $t('paymentMethods.addPaymentMethod') }}</v-btn>
    </v-container>
</template>

<script>
export default {
    name: 'PaymentDetails',
    data() {
        return {
            payments: [],
            accountid: "",
        };
    },
    mounted() {
        this.fetchPayments();
    },
    methods: {
        async fetchPayments() {
            try {
                const response = await this.$ApiService.fetchPayments();
                this.payments = response.data.map(payment => ({
                    paymentid: payment.paymentid,
                    "paymentname": `${payment.cardtype} (***${payment.cardnumber})`
                }));
            } catch (error) {
                //console.error("Failed to fetch payments:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
        addPaymentMethod(){
            this.$router.push('/payment-add');
        },
        async deletePaymentMethod(paymentId) {
            try {
                await this.$ApiService.deletePaymentMethod(paymentId);
                this.fetchPayments();
                this.$toast.success(this.$t('paymentMethods.paymentRemovedSuccess'));
            } catch (error) {
                //console.error("Failed to delete payment method:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>

<style scoped>
.v-card-item {
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
}

.select-icon-td {
  cursor: pointer;
}

.select-icon {
  transition: fill 0.3s ease;
}
 
.reduced-padding-margin  {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0;
  margin-right: 0;
}
</style>
