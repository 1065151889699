<template>
    <v-container>
        <h1>{{ $t('addAccount.title') }}</h1>
        <v-form>
            <v-autocomplete variant="underlined" v-model="merchantLocation" :label="$t('addAccount.location')" :items="locations" :min-length="2" :search-input="search" @update:search-input="val => search = val"></v-autocomplete>
            <v-autocomplete variant="underlined" v-model="merchantId" :label="$t('addAccount.store')" :items="merchants" item-value="merchantid" item-title="name"></v-autocomplete>
            <v-text-field persistent-placeholder :clearable="isFocused[0]" :label="$t('addAccount.accountName')" type="text" variant="underlined" v-model="accountName" @blur="isFocused[0] = false" @focus="isFocused[0] = true"></v-text-field>
            <v-text-field persistent-placeholder :clearable="isFocused[1]" :label="$t('addAccount.beneficiaryName')" type="text" variant="underlined" v-model="beneficiaryName" @blur="isFocused[1] = false" @focus="isFocused[1] = true"></v-text-field>
            <v-btn block class="my-4" color="primary" @click="addAccount">{{ $t('addAccount.addButton') }}</v-btn>
        </v-form>
    </v-container>
</template>

<script>


export default {
    name: 'AddAccount',
    data() {
        return {
            accountName: '',
            merchantId: '',
            merchantLocation: '',
            beneficiaryName: '',
            isFocused: [false, false],
            locations: [],
            merchants: [],
            search: '',
        };
    },
    watch: {
        search(val) {
            // Assuming you want to fetch the items dynamically based on the input
            if (val && val.length >= 2) {
          //      this.fetchItems(val);
            }
        },
    },
    mounted() {
        this.$store.commit('global/SET_LOADER', true);
        this.fetchMerchants();
        this.locations = [
            { value: "1", title: "Athens" },
            { value: "2", title: "Thessaloniki" },
            { value: "3", title: "Patra" },
        ];
        this.$store.commit('global/SET_LOADER', false);
    },
    methods: {
        async addAccount() {
            try {
                this.$store.commit('global/SET_LOADER', true);
                await this.$ApiService.addAccount({
                    accountName: this.accountName,
                    beneficiaryName: this.beneficiaryName,
                    merchantLocation: this.merchantLocation,
                    merchantId: this.merchantId,
                });
                this.$store.commit('global/SET_LOADER', false);
                this.$router.push('/');
            } catch (error) {
                //console.error("Failed to add account:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
        async fetchMerchants() {
            try {
                const response = await this.$ApiService.fetchMerchants();
                this.merchants = response.data;
            } catch (error) {
                //console.error("Failed to fetch merchants:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
