<template>
    <v-container>
        <h1>{{ $t('addBeneficiary.title') }}</h1>
        <v-form>
            <v-text-field maxlength="19" persistent-placeholder :clearable="isFocused[0]" :label="$t('addBeneficiary.nameLabel')" type="text" variant="underlined" v-model="name" @blur="isFocused[0] = false" @focus="isFocused[0] = true"></v-text-field>
            <v-btn block class="my-4" color="primary" @click="addBeneficiary">{{ $t('addBeneficiary.addButton') }}</v-btn>
        </v-form>
    </v-container>
</template>

<script>

export default {
    name: 'AddBeneficiary',
    data() {
        return {
            name: '',
            isFocused: [false],
        };
    },
    methods: {
        async addBeneficiary() {
            try {
                this.$store.commit('global/SET_LOADER', true);
                await this.$ApiService.addBeneficiary({name: this.name});
                this.$store.commit('global/SET_LOADER', false);
                this.$router.back();
            } catch (error) {
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
