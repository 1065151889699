import { createRouter, createWebHistory } from 'vue-router'
//import Dashboard from '@/views/Dashboard.vue';
import Home from '@/views/Home.vue';
import AccountDetails from '@/views/AccountDetails.vue';
import ViewTransactions from '@/views/ViewTransactions.vue';
import AccountTopUp from '@/views/AccountTopUp.vue';
import SetRestrictions from '@/views/SetRestrictions.vue';
import AddAccount from '@/views/AddAccount.vue';
import EditAccount from '@/views/EditAccount.vue';
import Login from '@/views/Login.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import NotificationsSetup from '@/views/NotificationsSetup.vue';
 
import AccountLimits from '@/views/AccountLimits.vue'; 
import ProductDetails from '@/views/ProductDetails.vue'; 
import AccountTransfer from '@/views/AccountTransfer.vue'; 
import AccountSettings from '@/views/AccountSettings.vue'; 
import Register from '@/views/Register.vue'; 
import UserSettings from '@/views/UserSettings.vue';
import PaymentMethodAdd from '@/views/PaymentMethodAdd.vue';
import PaymentMethodList from '@/views/PaymentMethodList.vue';
import BeneficiaryAdd from '@/views/BeneficiaryAdd.vue';
import BeneficiaryList from '@/views/BeneficiaryList.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import PasswordForgot from '@/views/PasswordForgot.vue';
 

//import store from '../store/index.js'; // Import your Vuex store
 
import store from '../store';

const routes = [
    //{ path: '/', name: 'dashboard', component: Dashboard },
    //{ path: '/manage-accounts', name: 'home', component: Home },
    { path: '/', name: 'home', component: Home },
    
    { path: '/account-details/:id', name: 'accountDetails', component: AccountDetails, props: true },
    { path: '/account-limits/:id', name: 'accountLimits', component: AccountLimits },
    { path: '/account-top-up/:id', name: 'accountTopUp', component: AccountTopUp ,props: true },
    { path: '/account-tranfer/:id', name: 'accountTransfer', component: AccountTransfer },
    { path: '/account-settings/:id', name: 'accountSettings', component: AccountSettings ,props: true},
    { path: '/payment-add', name: 'paymentMethodAdd', component: PaymentMethodAdd ,props: true},
    { path: '/payment-list', name: 'paymentsList', component: PaymentMethodList ,props: true},
    
    { path: '/password-reset', name: 'passwordReset', component: PasswordReset ,props: true},
    { path: '/password-forgot', name: 'passwordForgot', component: PasswordForgot ,props: true},

    { path: '/beneficiary-add', name: 'beneficiaryAdd', component: BeneficiaryAdd ,props: true},
    { path: '/beneficiary-list', name: 'beneficiaryList', component: BeneficiaryList ,props: true},
    
    { path: '/view-transactions/:id', name: 'viewTransactions', component: ViewTransactions },
    { path: '/set-restrictions', name: 'setRestrictions', component: SetRestrictions },
    { path: '/add-account', name: 'addAccount', component: AddAccount },
    { path: '/edit-account/:id', name: 'editAccount', component: EditAccount, props: true },
    { path: '/login', name: 'login', component: Login },
    { path: '/profile-settings', name: 'profileSettings', component: ProfileSettings },
    { path: '/notifications-setup', name: 'notificationsSetup', component: NotificationsSetup },
    { path: '/product-details/:id', name: 'productDetails', component: ProductDetails },
    { path: '/register', name: 'register', component: Register ,props: true},
    { path: '/settings', name: 'settings', component: UserSettings ,props: true},
    

    
    
   ]
 

const router = createRouter({ history: createWebHistory(), routes })


router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/register','/password-forgot','/password-reset']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('token')
  const viewName = to.name || to.meta.viewName; // Fallback to a meta field if you prefer
 // store.commit('viewname', viewName);
  store.dispatch('page/changepage',{viewname:viewName}).then(() => {
    if (authRequired && !loggedIn) {
      return next('/login')
    }
    next()
  })
  
})

export default router



