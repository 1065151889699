import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import { loadFonts } from './plugins/webfontloader'
import commonPlugIn from  './plugins/commonPlugIn'
import './assets/styles/global-style.css'; // Adjust the path as necessary
import Toast from "vue-toastification";
import { POSITION } from 'vue-toastification';
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { createI18n } from 'vue-i18n';

//import ApiService from '@/services/ApiService'

const toastOptions = {
  // You can set your default options here
   timeout: 1500,
   position: POSITION.BOTTOM_CENTER,
   containerClassName: "my-container-class" 
};
//loadFonts()

import en from './i18n/en.json';
import gr from './i18n/gr.json';
// Import other languages...

const messages = { en, gr }; // Add other languages here

const i18n = createI18n({
  locale: 'en', // Set the initial locale
  fallbackLocale: 'gr', // Set the fallback locale
  messages, // Set the messages
});

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(vuetify)
  .use(commonPlugIn)
  .use(Toast, toastOptions)
  .mount('#app')
