import ApiService from '@/services/ApiService';
import { useToast } from 'vue-toastification';

const commonPlugIn = {
    install(app) {
        app.config.globalProperties.$formatCurrency = function (number, locale = 'el-GR', currency = 'EUR') {
            var temp = Number(number)
            return temp.toLocaleString(locale, { style: 'currency', currency: currency });
        };
        app.config.globalProperties.$fixDate = function (timestamp) {
            // Parse the timestamp string as a Date object, assuming UTC by appending 'Z'
            const date = new Date(timestamp);

            // Extract the components of the date
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
            const year = date.getFullYear().toString().substr(-2); // Get last two digits of year
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');

            // Format the date components into the desired format
            return `${day}/${month}/${year} ${hours}:${minutes}`;
        };
        app.config.globalProperties.$typeDescription = function (transactionType) {
            const descriptions = {
                TopUp: "Added funds",
                Purchase: "Purchased",
                Refund: "Refunded",
                TransferFrom: "Sent money",
                TransferTo: "Received money",
                Cancellation: "Cancelled"
            };
 
            // Return the description if available, otherwise return the original transaction type
            return descriptions[transactionType] || transactionType;

        };
        app.config.globalProperties.$ApiService = ApiService;
        app.config.globalProperties.$toast = useToast();
        app.config.globalProperties.$loading = false;
         

        app.config.globalProperties.$errorHandler = function (view,error) {
            console.log(error);
            this.$toast.error(view+":"+error.desc,{timeout: 4000});
        }
    }
};

export default commonPlugIn;