<template>
    <v-container>
        <h1>{{ $t('login.title') }}</h1>
        <v-form>
            <v-text-field :label="$t('login.email')" v-model="email" variant="underlined"></v-text-field>
            <v-text-field :label="$t('login.password')" type="password" v-model="password" variant="underlined"></v-text-field>
            <v-btn block class="my-4 action" color="primary" @click="login">{{ $t('login.loginButton') }}</v-btn>
            <div block class="my-4 action" color="primary" @click="register">{{ $t('login.register') }}</div>
        </v-form>
        <div block class="my-4 action" color="primary" @click="forgot">{{ $t('login.forgot') }}</div>
    </v-container>
</template>

<script>
export default {
    name: 'Login',

    data() {
        return {
            email: '',
            password: '',
        };
    },
    mounted(){
        this.$store.commit('global/SET_LOADER', false);
    },
    methods: {
        async login() {
            try {
                this.$store.commit('global/SET_LOADER', true);
                await this.$ApiService.login({
                    email: this.email,
                    password: this.password,
                });
                this.$store.commit('global/SET_LOADER', false);
                this.$router.push('/');
            } catch (error) {
                //console.error("Failed to login:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
        register() {
            this.$router.push('/register');
        },
        forgot() {
            this.$router.push('/password-forgot');
        }
    },
};
</script>