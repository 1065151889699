<template>
    <v-container>
        <h1>{{ $t('register.title') }}</h1>
        <v-form>
            <v-text-field :label="$t('register.name')" v-model="name" variant="underlined"></v-text-field>
            <v-text-field :label="$t('register.email')" v-model="email" variant="underlined"></v-text-field>
            <v-text-field :label="$t('register.password')" type="password" v-model="password" variant="underlined"></v-text-field>
            <v-btn block class="my-4 action" color="primary" @click="register">{{ $t('register.registerButton') }}</v-btn>
        </v-form>
    </v-container>
</template>

<script>
export default {
    name: 'Register',
    data() {
        return {
            name: '',
            email: '',
            password: '',
        };
    },
    mounted(){
        this.$store.commit('global/SET_LOADER', false);
    },
    
    methods: {
        async register() {
            try {
                this.$store.commit('global/SET_LOADER', true);
                await this.$ApiService.register({
                    name: this.name,
                    email: this.email,
                    password: this.password,
                });
                this.$store.commit('global/SET_LOADER', false);
                this.$router.push('/');
            } catch (error) {
                //console.error("Failed to register:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
