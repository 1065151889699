<template>
    <v-container v-if="products.length > 0">
        <h1>{{ $t('productCatalog.title') }}</h1>
        <v-card class="text-left mx-auto my-3">
            <v-card-text class="text-left my-1 py-1 text-disabled">
                {{ $t('productCatalog.category01') }}
            </v-card-text>
            <v-table style="background-color:transparent" class="py-0">
                <tbody>
                    <tr v-for="product in products" :key="product.productid">
                        <td @click="product.selected = !product.selected"  class="select-icon-td">
                            <!-- Lighter circle for selected -->
                            <div class="vertical-align">
                            <svg-icon v-if="product.selected" type="mdi" :path="pathCheck"></svg-icon>
                            <!-- Darker circle for non-selected -->
                            <svg-icon v-else type="mdi" :path="pathNotCheck"></svg-icon> 
                            
                        </div>
                        </td>
                        <td>
                            <div class="vertical-align">{{ product.name }}
                             <p class="text-disabled">{{ product.details }}</p>
                        </div>    
                         
                        </td>
                        <td class="text-right">{{ product.price }} €</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
        <v-btn block class="my-4" color="primary" @click="updateAccountProducts">{{ $t('productCatalog.saveButton') }}</v-btn>
    </v-container>
</template>

<script>

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCheckCircle, mdiCircleOutline,  } from '@mdi/js';



export default {
    name: 'ProductDetails',
    components: {
        SvgIcon
    },
    data() {
        return {
            products: [],
            merchantProducts: [],
            accountid: "",
            pathCheck: mdiCheckCircle,
            pathNotCheck:mdiCircleOutline
        };
    },
    mounted() {
        this.$store.commit('global/SET_LOADER', true);
        this.accountid = this.$route.params.id;
        this.fetchMerchantProducts();
        this.$store.commit('global/SET_LOADER', false);
    },
    methods: {
        async fetchMerchantProducts() {
            try {
                const response = await this.$ApiService.fetchMerchantProducts(this.accountid);
                this.merchantProducts = response.data.map(product => ({
                    ...product,
                    selected: false, // Initialize all products as not selected by default
                }));
                this.fetchAccountProducts();
            } catch (error) {
                //console.error("Failed to fetch merchant products:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
        async fetchAccountProducts() {
            try {
                const response = await this.$ApiService.fetchAccountProducts(this.accountid);
                const accountSelectedProducts = response.data.map(id => id.trim().toLowerCase());
                this.products = this.merchantProducts.map(product => ({
                    ...product,
                    selected: accountSelectedProducts.includes(product.productid.trim().toLowerCase()),
                }));
            } catch (error) {
                //console.error("Failed to fetch account products:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
        async updateAccountProducts() {
            const selectedProductIds = this.products
                .filter(product => product.selected)
                .map(product => product.productid);

            try {
                await this.$ApiService.updateAccountProducts(this.accountid, selectedProductIds);
               this.$toast.success(this.$t('productCatalog.saveSuccessMessage'), { onClose: () => { this.$router.back(); } });
            } catch (error) {
                //console.error("Failed to update account products:", error);
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>

<style scoped>
.select-icon-td {
  cursor: pointer;
}

.select-icon {
  transition: fill 0.3s ease;
}

.reduced-padding-margin {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0;
  margin-right: 0;
}

.vertical-align {
    display: flex;
}
</style>
