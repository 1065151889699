<template>
    <v-container>
        <h1>{{ $t('accountLimits.title') }}</h1>
        <v-form>
            <v-text-field persistent-placeholder @input="validateAndFormatAmount('dailylimit')" inputmode="decimal" :placeholder="$t('accountLimits.nolimits')" pattern="[0-9]*" type="text" clearable :prefix="$t('accountLimits.currencyPrefix')" :label="$t('accountLimits.dailyLimit')" variant="underlined" v-model="account.dailylimit"></v-text-field>
            <v-text-field persistent-placeholder @input="validateAndFormatAmount('weeklylimit')" inputmode="decimal" :placeholder="$t('accountLimits.nolimits')" pattern="[0-9]*" type="text" clearable :prefix="$t('accountLimits.currencyPrefix')" :label="$t('accountLimits.weeklyLimit')" variant="underlined" v-model="account.weeklylimit" :error-messages="!isWeeklyLimitValid ? $t('accountLimits.weeklyLimitError') : ''"></v-text-field>
            <v-text-field persistent-placeholder @input="validateAndFormatAmount('monthlylimit')" inputmode="decimal" :placeholder="$t('accountLimits.nolimits')" pattern="[0-9]*" type="text" clearable :prefix="$t('accountLimits.currencyPrefix')" :label="$t('accountLimits.monthlyLimit')" variant="underlined" v-model="account.monthlylimit" :error-messages="!isMonthlyLimitValid ? $t('accountLimits.monthlyLimitError') : ''"></v-text-field>
            <v-btn block class="my-4" color="primary" @click="transfer">{{ $t('accountLimits.confirmButton') }}</v-btn>
        </v-form>
    </v-container>
</template>
:value="getValue('field2')"

<script>
export default {
    name: 'AccountLimits',
    data() {
        return {
            accountid: '',
            account: {},
        };
    },
    mounted() {
        this.$store.commit('global/SET_LOADER', true);
        this.accountid = this.$route.params.id;
        this.fetchAccountDetails();
        this.$store.commit('global/SET_LOADER', false);
    },
    computed: {
        isWeeklyLimitValid() {
            const dailyLimit = parseFloat(this.account.dailylimit);
            const weeklyLimit = parseFloat(this.account.weeklylimit);
            if (!this.account.weeklylimit || weeklyLimit === 0 || !this.account.dailylimit || dailyLimit === 0) return true;
            return weeklyLimit >= dailyLimit;
        },
        isMonthlyLimitValid() {
            const weeklyLimit = parseFloat(this.account.weeklylimit);
            const monthlyLimit = parseFloat(this.account.monthlylimit);
            if (!this.account.monthlylimit || monthlyLimit === 0 || !this.account.weeklylimit || weeklyLimit === 0) return true;
            return monthlyLimit >= weeklyLimit;
        }
    },
    methods: {
        async fetchAccountDetails() {
            try {
                const response = await this.$ApiService.fetchAccountDetails(this.accountid);
                this.account = response.data;
                Object.keys(this.account).forEach(key => { if (this.account[key] === "0.00") { this.account[key] = null; } });

            } catch (error) {
                this.$errorHandler(this.$options.name, error)
            }
        },
        async transfer() {
            try {
                var { dailylimit, weeklylimit, monthlylimit } = this.account;
                dailylimit = dailylimit ?? 0; weeklylimit = weeklylimit ?? 0; monthlylimit = monthlylimit ?? 0;
                var params = { dailylimit, weeklylimit, monthlylimit }
                console.log(params)
                await this.$ApiService.updateAccountLimits(this.accountid, params);
                this.$toast.success(this.$t('accountLimits.updateSuccess'), { onClose: () => { this.$router.back(); } })
            } catch (error) {
                this.$errorHandler(this.$options.name, error)
            }
        },
        validateAndFormatAmount(field) {
         
            
            let value = parseFloat(this.account[field]);

            if (!isNaN(value)) {
                value = Math.min(1000, value);
                this.account[field] = value
            } else {
                this.account[field] = null;
            }  
        }

    },
};
</script>
