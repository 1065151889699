<template>
    <v-container>
        <h1>{{ $t('addPaymentMethod.title') }}</h1>
        <v-form ref="form" v-model="valid">
            <v-text-field :error-messages="cardNumberErrors" maxlength="19" pattern="[0-9]*" type="text" persistent-placeholder :clearable="isFocused[0]" :label="$t('addPaymentMethod.cardNumber')"   variant="underlined" v-model="cardNumber" @blur="isFocused[0] = false" @focus="isFocused[0] = true" :attrs="{ inputmode: 'decimal', pattern: '[0-9]*' }"></v-text-field>
            <v-text-field :error-messages="expirationDateErrors" maxlength="5" pattern="[0-9]*" type="text" persistent-placeholder :clearable="isFocused[1]" :label="$t('addPaymentMethod.expirationDate')"  variant="underlined" v-model="expirationDate" @blur="isFocused[1] = false" @focus="isFocused[1] = true" :attrs="{ inputmode: 'decimal', pattern: '[0-9]*' }"></v-text-field>
            <v-text-field :error-messages="cvvErrors" maxlength="3" pattern="[0-9]*" type="text" persistent-placeholder :clearable="isFocused[2]" :label="$t('addPaymentMethod.cvv')"  variant="underlined" v-model="cvv" @blur="isFocused[2] = false" @focus="isFocused[2] = true" :attrs="{ inputmode: 'decimal', pattern: '[0-9]*' }"></v-text-field>
            <v-btn :disabled="!valid" block class="my-4" color="primary" @click="addPaymentMethod">{{ $t('addPaymentMethod.addButton') }}</v-btn>
        </v-form>
    </v-container>
</template>


<script>
export default {
    name: 'AddPaymentMethod',
    data() {
        return {
            cardNumber: '',
            expirationDate: '',
            cvv: '',
            ownerId: '', // Placeholder for owner ID
            isFocused: [false, false, false],
            toCheck: false
        };
    },
    watch: {
        cardNumber(newVal) {
            let value = (newVal===null)?'':newVal.replace(/\D/g, '');
            value = value.replace(/(\d{4})(?=\d)/g, '$1 ');
            this.cardNumber = value;
        },
        expirationDate(newVal) {
            let value = (newVal===null)?'':newVal.replace(/\D/g, '') || '';
            if (value.length > 2) {
                value = value.slice(0, 2) + '/' + value.slice(2);
            }
            this.expirationDate = value.substring(0, 5);
        },
        cvv(newVal) {
            let value = (newVal===null)?'':newVal.replace(/\D/g, '') || '';
            this.cvv = value.substring(0, 3);
        }
    },
    computed: {
        cardNumberErrors() {
            const errors = [];
            if (!this.toCheck) { return errors }
            // Adjusted regex to allow spaces every 4 digits as added by watcher
            const cardNumber = this.cardNumber.replace(/\s/g, ''); // Remove spaces for validation
            if (!cardNumber.match(/^\d{16}$/)) {
                errors.push(this.$t('addPaymentMethod.errors.invalidCard'));
            } else if (!this.isValidCardNumber(cardNumber)) {
                errors.push(this.$t('addPaymentMethod.errors.invalidCheckDigit'));
            }
            return errors;
           
        },
        expirationDateErrors() {
            const errors = [];
            if (!this.toCheck) { return errors }
            // Adjusted to allow for MM/YY format explicitly
            if (!this.expirationDate || !this.expirationDate.match(/^(0[1-9]|1[0-2])\/\d{2}$/)) {
                errors.push(this.$t('addPaymentMethod.errors.invalidDate'));
            } else {
                const currentDate = new Date();
                const monthYear = this.expirationDate.split('/');
                const expiration = new Date(parseInt(monthYear[1], 10) + 2000, parseInt(monthYear[0], 10) - 1);
                if (expiration <= currentDate) {
                    errors.push(this.$t('addPaymentMethod.errors.pastDate'));
                }
            }
            return errors;
        },
        cvvErrors() {
            const errors = [];
            if (!this.toCheck) { return errors }
            if (!this.cvv || !this.cvv.match(/^\d{3}$/)) {
                errors.push(this.$t('addPaymentMethod.errors.invalidCVV'));
            }
            return errors;
        },
        valid() {
            return this.cardNumberErrors.length === 0 && this.expirationDateErrors.length === 0 && this.cvvErrors.length === 0;
        }
    },
    methods: {
        async addPaymentMethod() {
            try {
                this.toCheck = true;
                if (!this.valid) { return }
                var params = {
                    cardNumber: this.cardNumber.replace(/\s+/g, ''),
                    expirationDate: this.expirationDate.replace(/\//g, ''),
                }
                console.log(params)

                // Call the external service to get the token, card type, and last four digits
                /* const cardDetails = await ApiService.getTokenAndCardDetails({
                    cardNumber: this.cardNumber,
                    expirationDate: this.expirationDate,
                    cvv: this.cvv,
                }); */
                const cardDetails = {
                    cardNumber: '1234', // Storing only the last four digits for security reasons
                    cardType: 'Visa',
                    token: 'asdfgh12345',
                    lastFourDigits: '1234'
                }
                if (!cardDetails.token) {
                    throw new Error("Failed to retrieve card token and details");
                }
                // Now call your existing API to add the payment method along with the retrieved token and card details
                await this.$ApiService.addPaymentMethod({
                    cardNumber: cardDetails.lastFourDigits, // Storing only the last four digits for security reasons
                    cardType: cardDetails.cardType,
                    token: cardDetails.token
                });
                // Success handling
                this.$router.back();
            } catch (error) {
                console.error(error);
                // Error handling, possibly staying on the current page or showing an error message
            }
        },
        isValidCardNumber(number) {
            let sum = 0;
            let alternate = false;
            for (let i = number.length - 1; i >= 0; i--) {
                let n = parseInt(number.substring(i, i + 1), 10);
                if (alternate) {
                    n *= 2;
                    if (n > 9) {
                        n = (n % 10) + 1;
                    }
                }
                sum += n;
                alternate = !alternate;
            }
            return (sum % 10 === 0);
        },



    },
};
</script>
