<template>
  <v-container>
    <h1>{{ $t('setRestrictions.title') }}</h1>
    <v-form>
      <v-select :label="$t('setRestrictions.selectAccount')" :items="accounts" item-text="name" item-value="id" v-model="selectedAccount"></v-select>
      <v-checkbox v-for="product in products" :key="product.id" v-model="selectedProducts" :label="product.name" :value="product.id"></v-checkbox>
      <v-btn color="primary" @click="setRestrictions">{{ $t('setRestrictions.setRestrictionsButton') }}</v-btn>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: 'SetRestrictions',
  data() {
    return {
      accounts: [],
      selectedAccount: '',
      products: [],
      selectedProducts: [],
    };
  },
  mounted() {
    this.fetchAccounts();
    this.fetchProducts(); // Assuming you need to fetch the products
  },
  methods: {
    async fetchAccounts() {
      try {
        const response = await this.$ApiService.fetchAccounts();
        this.accounts = response.data;
      } catch (error) {
        console.error("Failed to fetch accounts:", error);
      }
    },
    async fetchProducts() {
      try {
        // Assuming there's a method to fetch products
        const response = await this.$ApiService.fetchProducts();
        this.products = response.data;
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    },
    async setRestrictions() {
      try {
        // Assuming `setRestrictions` method requires selected account ID and an array of selected product IDs
        await this.$ApiService.setRestrictions(this.selectedAccount, this.selectedProducts);
        // Assuming toast or notification for success feedback
        this.$toast.success(this.$t('setRestrictions.successMessage'));
      } catch (error) {
        //console.error("Failed to set restrictions:", error);
        // Assuming toast or notification for error feedback
        //this.$toast.error(this.$t('setRestrictions.errorMessage'));
        this.$errorHandler(this.$options.name,error)
      }
    },
  },
};
</script>
