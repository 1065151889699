<template>
  <v-app style="max-width: 420px; margin: auto;">
    <v-main>
      
        <div :key="$route.fullPath">
          <Navbar />
          <router-view></router-view>
        </div>
      
    </v-main>
  </v-app>
</template>


<script>
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  },

  data: () => ({
    //
  }),
}
//.right-aligned-textfield ::v-deep input {
//  text-align: right;
//}
</script>

<style>
@import '@/assets/styles/global-style.css';
</style>
<style>

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
   
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
 
v-toolbar {
  width: 100%;
}

.v-container {
  max-width: 420px;
  width: 100%;
  margin: auto;
  text-align: center;
}

.v-btn.action {
  padding: 15px;
  width: 100%;
  margin-bottom: 2rem;
  background-color: #3060f0;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
 
  text-transform: uppercase;
}

.lv-btn:hover,
.sign-up-button:hover {
  background-color: #204cbf;
}


h1 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: left;
}


 
 </style>