<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="8">
                <h1 class="my-0 mx-1">{{ account.accountname }}</h1>
                <div class="text-h7 mx-1 text-left">{{ $formatCurrency(balance) }}</div>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-avatar class="text-center" color="surface-variant" size="64">
                    <!-- If there is an imageUrl, show it as an img element -->
                    <img v-if="imageUrl" :src="imageUrl" :alt="'User Avatar'">
                    <!-- If there is no imageUrl, fallback to an svg-icon -->
                    <svg-icon :color="'white'" :size="36" v-else type="mdi" :path="fallbackIconPath"></svg-icon>
                </v-avatar>

 
            </v-col>
        </v-row>
        <!-- Account details display here -->
        <v-card class="text-left mx-auto my-6">

            <v-table style="background-color:transparent" class="py-1" v-if="transactions.length > 0">
                <tbody>
                    <tr v-for="transaction in transactions" :key="transaction.id">
                        <td class="text-left py-2" style="vertical-align: top;">{{ $typeDescription(transaction.type) }}<p class="text-disabled">{{ $fixDate(transaction.created_at) }}</p>
                        </td>
                        <td class="text-right py-2" style="vertical-align: top;">{{ $formatCurrency(transaction.amount) }}</td>
                    </tr>

                </tbody>
            </v-table>
            <v-row no-gutters>
                <v-col cols="12" class="py-1 text-disabled text-center" @click="$router.push(`/view-transactions/${accountid}`)">{{ $t('accountDetails.more') }}</v-col>
            </v-row>
        </v-card>

        <v-card class="text-left mx-auto my-3">
            <v-table style="background-color:transparent">
                <tbody>
                    <tr v-for="action in actions" :key="action.id" @click="this.$router.push(`/${action.path}/${account.accountid}`);">
                        <td class="text-left">
                            <div class="vertical-align">
                                <svg-icon class="mx-2 pt-1" type="mdi" :path="path[action.id]"></svg-icon>
                                <span class="mx-2 my-1">{{ $t(`accountDetails.${action.titleKey}`) }}</span>
                            </div>
                        </td>
                        <td class="text-right">
                            <div class="vertical-align" style="justify-content: right;">
                                <svg-icon type="mdi" :path="pathChevronRight"></svg-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>


    </v-container>
</template>

<script>
//
/*
<tr v-for="action in actions" :key="action.id" @click="this.$router.push(`/${action.path}/${account.accountid}`);">
    <td class="text-left">{{ $t(`accountDetails.${action.title}`) }}</td>
    <td class="text-right"><img class="icon-sm" src="/right-arrow.png" /></td>
</tr>
*/
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiSwapHorizontal, mdiChevronRight, mdiCashPlus, mdiNutrition, mdiCarSpeedLimiter, mdiAccountCog ,mdiCameraOutline} from '@mdi/js';


export default {
    name: 'AccountDetails',
    components: {
        SvgIcon
    },
    props: {
        id: String,
    },
    data() {
        return {
            accountid: this.$route.params.id,
            imageUrl:null,
            actions: [],
            account: {},
            balance: 0,
            transactions: [],
            flag: true,
            path: [mdiCashPlus, mdiNutrition, mdiSwapHorizontal, mdiCarSpeedLimiter, mdiAccountCog],
            pathChevronRight: mdiChevronRight,
            fallbackIconPath:mdiCameraOutline
        };
    },
    mounted() {

        this.$store.commit('global/SET_LOADER', true);
        this.fetchAccountDetails();
        this.fetchAccountBalance();
        this.fetchTopTransaction()

        this.actions = [
            { id: 0, titleKey: "topUpAccountTitle", subtitleKey: "topUpAccountSubtitle", path: "account-top-up" },
            { id: 1, titleKey: "productCatalogTitle", subtitleKey: "productCatalogSubtitle", path: "product-details" },
            { id: 2, titleKey: "transferBetweenAccountsTitle", subtitleKey: "transferBetweenAccountsSubtitle", path: "account-tranfer" },
            { id: 3, titleKey: "accountLimitTitle", subtitleKey: "accountLimitSubtitle", path: "account-limits" },
            { id: 4, titleKey: "settingsTitle", subtitleKey: "settingsSubtitle", path: "account-settings" },
        ];
        this.$store.commit('global/SET_LOADER', false);
    },
    methods: {
        async fetchAccountDetails() {
            try {
                const response = await this.$ApiService.fetchAccountDetails(this.accountid);
                this.account = response.data;
            } catch (error) {
                this.$errorHandler(this.$options.name, error)
            }
        },
        async fetchAccountBalance() {
            try {
                const response = await this.$ApiService.fetchSingleAccountBalance(this.accountid);

                this.balance = response.data.total;
            } catch (error) {
                this.$errorHandler(this.$options.name, error)
            }
        },
        async fetchTopTransaction() {
            try {
                const response = await this.$ApiService.fetchAccountTransaction(this.accountid, { limit: 1 });
                this.transactions = response.data;
            } catch (error) {
                this.$errorHandler(this.$options.name, error)
            }
        }

    },
};
</script>

<style scoped>
.vertical-align {
    display: flex;
}
</style>