<template>
    <v-container>
        <h1>{{ $t('profileSettings.title') }}</h1>
        <v-form>
            <v-text-field :label="$t('profileSettings.email')" v-model="user.email"></v-text-field>
            <v-text-field :label="$t('profileSettings.newPassword')" type="password" v-model="user.newPassword"></v-text-field>
            <v-btn color="primary" @click="updateProfile">{{ $t('profileSettings.saveChanges') }}</v-btn>
        </v-form>
    </v-container>
</template>

<script>
export default {
    name: 'ProfileSettings',
    data() {
        return {
            user: {
                email: '',
                newPassword: '',
            },
        };
    },
    methods: {
        async updateProfile() {
            try {
                await this.$ApiService.updateProfile({
                    email: this.user.email,
                    password: this.user.newPassword,
                });
                // Assuming toast or notification for success feedback
                this.$toast.success(this.$t('profileSettings.successMessage'));
            } catch (error) {
                //console.error("Failed to update profile:", error);
                // Assuming toast or notification for error feedback
                //this.$toast.error(this.$t('profileSettings.errorMessage'));
                this.$errorHandler(this.$options.name,error)
            }
        },
    },
};
</script>
