<template>
  <v-toolbar density="compact" style="max-width: 420px;margin: auto;" color="primary">
    <template v-if="currentView != 'home' && currentView != 'login'">
      <svg-icon class="mx-4" type="mdi" @click="$router.back()" :path="path"></svg-icon>
    </template>


    <template v-if="currentView == 'home'">
      <div class="d-flex justify-space-between" style="width: 100%;">
        <svg-icon class="mx-4 my-3" type="mdi" @click="logout" :path="pathlogout"></svg-icon>
        <svg-icon class="mx-4 my-3" type="mdi" @click="settings" :path="pathsettings"></svg-icon>
      </div>
    </template>

    <template v-if="currentView == 'login'">
      <div class="d-flex justify-space-between" style="width: 100%;">
      </div>
    </template>

    <template v-if="currentView == 'viewTransactions1'">
      <v-btn text @click="$router.push('/')">{{ $t('navbar.myDashboard') }}</v-btn>
    </template>

    <template v-if="currentView == 'home1'">
      <v-btn text @click="$router.back()">{{ $t('navbar.back') }}</v-btn>
      <v-btn text @click="$router.push('/add-account')"><v-icon icon="mdi-plus"></v-icon> {{ $t('navbar.addAccount') }}</v-btn>
    </template>

    <template v-if="isLoggedIn">
      <v-btn text v-if="userRole === 'owner'" @click="$router.push('/owner-dashboard')">{{ $t('navbar.ownerDashboard') }}</v-btn>
      <v-btn text v-if="userRole === 'merchant'" @click="$router.push('/merchant-dashboard')">{{ $t('navbar.merchantDashboard') }}</v-btn>
      <v-btn text v-if="userRole === 'beneficiary'" @click="$router.push('/beneficiary-dashboard')">{{ $t('navbar.beneficiaryDashboard') }}</v-btn>
      <v-btn text @click="logout">{{ $t('navbar.logout') }}</v-btn>
    </template>
    <v-btn text v-if="isLoggedIn" @click="goToLogin">{{ $t('navbar.login') }}</v-btn>
    <v-progress-linear :active="loader" :indeterminate="loader" color="primary" absolute bottom></v-progress-linear>

  </v-toolbar>
</template>




<script>
import { mapGetters, mapActions } from 'vuex';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiArrowLeft, mdiLogout, mdiCogs } from '@mdi/js';
//import { store } from '../store/store.js';
//import { useStore } from 'vuex';
//const store = useStore();
//import { useRoute } from 'vue-router';

export default {
  components: {
    SvgIcon
  },
  data() {
    return {
      pageName: "",
      isLoggedIn: false,
      path: mdiArrowLeft,
      pathlogout: mdiLogout,
      pathsettings: mdiCogs
    };
  },

  computed: {
    ...mapGetters('page', ['viewname']),
    userRole() {
      // Assume user role is stored in the Vuex store or local storage
      return this.$store.state.auth.user.role || localStorage.getItem('userRole');
    },
    currentView() {
      return this.$store.state.page.viewname;
    },

    loader() {
      return this.$store.state.global.loader
    }

  },

  methods: {
    ...mapActions('auth', ['logout']),
    goToLogin() {
      this.$router.push('/login');
    },
    settings() {
      this.$router.push('/settings');
    },
    logout() {
      localStorage.removeItem('token')
      this.$router.push('/login');
    },
  },
};
</script>
