<template >
    <v-container>
        <h1>{{ $t('settings.title') }}</h1>
        <v-card class="text-left mx-auto my-3">
            <v-table style="background-color:transparent">
                <tbody>
                    <tr v-for="action in actions" :key="action.id" @click="eventaction(action)">
                        
                        <td class="text-left">
                            <div class="vertical-align py-1 my-1">
                                <svg-icon class="mx-2  my-1 pt-1" type="mdi" :path="path[action.id]"></svg-icon>
                                <p class="mx-2 my-2" >{{ $t(`${action.title}`) }}</p>
                            </div>
                        </td>
                        <td class="text-right">
                            <div class="vertical-align" style="justify-content: right;">
                                <svg-icon type="mdi" :path="pathChevronRight"></svg-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
    </v-container>
</template>

<script>

 
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiLockReset, mdiChevronRight, mdiWalletBifold, mdiAccountMultiple, mdiWeb, mdiThemeLightDark } from '@mdi/js';

 

export default {
    name: 'UserSettings',
    components: {
        SvgIcon
    },
    props: {
        id: String,
    },
    data() {
        return {
            actions: [],
            account: {},
            pathChevronRight: mdiChevronRight,
            path: [mdiWalletBifold, mdiAccountMultiple, mdiLockReset, mdiWeb, mdiThemeLightDark],
            
        };
    },
    mounted() {
        this.$store.commit('global/SET_LOADER', false);
        this.$vuetify.theme.dark = true
        this.actions = [
            { id: 0, title: "settings.actions.1.title", subtitle: "settings.actions.1.subtitle", path: "payment-list" },
            { id: 1, title: "settings.actions.2.title", subtitle: "settings.actions.2.subtitle", path: "beneficiary-list" },
            { id: 2, title: "settings.actions.3.title", subtitle: "settings.actions.3.subtitle", path: "password-reset" },
            { id: 3, title: "settings.actions.4.title", subtitle: "settings.actions.4.subtitle", action: "language" },
            { id: 4, title: "settings.actions.5.title", subtitle: "settings.actions.5.subtitle", action: "darkmode" },
        ]
    },
    methods: {
        eventaction(item) {
            if (item.path) { this.$router.push(`/${item.path}`); return }
            else this[item.action]()
        },
        language() {
            this.$i18n.locale = (this.$i18n.locale == "en")?"gr":"en";
        },
        darkmode() {
            console.log("dark1")
            console.log(this.$vuetify.theme)
            //            this.$vuetify.theme.dark = false;
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.$forceUpdate();
        }
    },
};
</script>
<style scoped>
.vertical-align {
    display: flex;
}
</style>